import styled from "styled-components";
import I_copy from "../../img/icon/I_copy.svg";
import I_circleChk from "../../img/icon/I_circleChk.svg";
import { D_recommendList } from "../../data/DmyPage";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { strDot } from "../../util/Util";
import { onclickcopy } from "../../util/common.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../configs/api";
import { getmyaddress, LOGGER } from "../../util/common";
import SetErrorBar from "../../util/SetErrorBar.js";
import { messages } from "../../configs/messages";
import { TIME_FETCH_MYADDRESS_DEF } from "../../configs/configs";
import { net } from "../../configs/net";

export default function Jackpot() {
  const isMobile = useSelector((state) => state.common.isMobile);
  const navigate = useNavigate();

  const [userinfoProp, setUserinfoProp] = useState([]);
  const [jackpotNum, setJackpotNum] = useState();

  function jackpotClaim() {
    const query = 'input[name="kingkong"]:checked';
    const selectedEls = document.querySelectorAll(query);
    let isprize = 0;
    let usekingkong='';
    let myaddress = getmyaddress();
    selectedEls.forEach((el) => {
      isprize += parseInt(el.getAttribute('data').slice(-2));
      usekingkong += el.getAttribute('data') + ',';
    });

    if(isprize == parseInt(jackpotNum)) {
      usekingkong = usekingkong.slice(0, -1);
      SetErrorBar('Your application has been completed.');
        axios.post(API.API_JACKPOT_LIST_POST, {
          usekingkong,
          myaddress
      });
    } else {
      SetErrorBar('The sum of the two digits at the end of the King Kong number does not match the winning number.');
    }
    
  }

  const fetchdataUserInfo = async (_) => {
    axios.get(API.API_JACKPOT_NUM + `/1/0/120/id/DESC`).then((resp) => {
      console.log("JACKPOT_NUM", resp.data);
      let { status, list } = resp.data;
      if (status == "OK") {
        setJackpotNum(list[0].num);
      }
    })
    let myaddress = getmyaddress();
    if (myaddress) {
      axios.get(API.API_USERINFO + `/${myaddress}?nettype=${net}`).then((resp) => {
        let { status, respdata } = resp.data;
        LOGGER("userInfo", respdata);
        if (status == "OK") {
          setUserinfoProp(respdata);
            axios.get(API.API_LOGSALES + `/${myaddress}?nettype=${net}`).then((resp) => {
              console.log("myRefererList", resp.data);
              let { status, list } = resp.data;
              if (status == "OK" && list != undefined) {
                for(var i=0; i<list.length; i++) {
                  console.log(list[i].itemid);
                  axios.get(API.API_ITEMLIST + `/${list[i].itemid}?nettype=${net}`).then((resp) => {
                    console.log("itemlist", resp.data);
                    let { status, list } = resp.data;
                    if (status == "OK") {
                      LOGGER("konglist", list[0].titlename, list[0].group_)
                      if(list[0].group_ == 'kingkong'){
                        const temp = document.createElement('li');
                        temp.innerHTML = '<label><img src="'+list[0].url+'"/><br><input type="checkbox" data="'+list[0].titlename+'" name="kingkong"/><p>'+list[0].titlename+'</p></label>'
                        document.querySelector('#kingkonglist').append(temp);
                      }
                    }
                });
                }
              }
            });
        }
      });
    } else {
      SetErrorBar(messages.MSG_CONNECTWALET);
    }
  };
  useEffect((_) => {
    fetchdataUserInfo();
    return () => {};
  }, []);

    return (
      <PrecommendBox>
        <div className="prizeNum">
          <p>PRIZENUMBER : {jackpotNum}</p>
          <button
            onClick={() => jackpotClaim()}
          >
            claim
          </button>
        </div>
        <p className="description">You can apply if the total of the last two digits of King Kong Number is the same as the winning number.</p>

        <h2 className="title">My KingKong List</h2>
        <ul id="kingkonglist"></ul>       
      </PrecommendBox>
    );
}

const PrecommendBox = styled.section`
  padding: 60px 0 0 0;

  * {
    font-family: "Roboto", sans-serif;
  }

  .prizeNum { background: #000; color: #fff; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap: 30px; padding: 10px; font-size: 25px; border-radius: 15px; margin-bottom:10px;}
  .prizeNum button {background:#fff; color:#000; font-weight:bold; padding:10px 20px; border-radius:15px; font-size:16px;}
  .description {margin-bottom:30px; font-weight:bold;}
  h2.title {margin-bottom:30px;}
  #kingkonglist {overflow:hidden;} 
  #kingkonglist li { float:left; width:23%; margin-right:2%; margin-bottom:2%;}
  #kingkonglist li:last-of-type {margin-right:0;}
  #kingkonglist li input {display:inline-block;}
  #kingkonglist li p {display:inline-block;margin-left:10px;}
  img { width:100%; }
  
  
  @media screen and (max-width:768px) {
    .prizeNum {width:90%;margin:0 auto 10px;}
    .description {width:90%;margin:0 auto 30px;}
    h2.title {width:90%; margin:0 auto; margin-bottom:30px;}
    #kingkonglist {width:90%; margin:0 auto;}
    #kingkonglist li {width:48%;}
    #kingkonglist li:nth-of-type(2n) {margin-right:0;}
  }
`;

