import E_auctionProf1 from "../img/main/E_auctionProf1.png";
import E_auctionProf2 from "../img/main/E_auctionProf2.png";
import E_auctionProf3 from "../img/main/E_auctionProf3.png";
import E_auctionProf4 from "../img/main/E_auctionProf4.png";
import E_auctionItem1 from "../img/main/E_auctionItem1.png";
import E_auctionItem2 from "../img/main/E_auctionItem2.png";
import E_auctionItem3 from "../img/main/E_auctionItem3.png";
import E_auctionItem4 from "../img/main/E_auctionItem4.png";

const D_auctionItemList = [
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
  {
    profImg: E_auctionProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_auctionItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
  },
];

const D_sortList = [
  "Rarity",
  "Price : Low to High",
  "Price : High to Low",
  "Recently Listed",
  "Oldest",
];

export { D_auctionItemList, D_sortList };
