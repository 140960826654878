import E_marketProf1 from "../img/main/E_marketProf1.png";
import E_marketProf2 from "../img/main/E_marketProf2.png";
import E_marketProf3 from "../img/main/E_marketProf3.png";
import E_marketProf4 from "../img/main/E_marketProf4.png";
import E_marketItem1 from "../img/main/E_marketItem1.png";
import E_marketItem2 from "../img/main/E_marketItem2.png";
import E_marketItem3 from "../img/main/E_marketItem3.png";
import E_marketItem4 from "../img/main/E_marketItem4.png";

const D_marketItemList = [
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf1,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem1,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf2,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem2,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf3,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem3,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
  {
    profImg: E_marketProf4,
    address: "0x5c7MMMMd8d7",
    like: 22,
    item: E_marketItem4,
    title: "Kingkong #1",
    price: 0.5,
    unit: "USDT",
    time: "12h 54m 55s",
  },
];

const D_sortList = [
  "Rarity",
  "Price : Low to High",
  "Price : High to Low",
  "Recently Listed",
  "Oldest",
];
const E_sortList = [
  { title: "Employed NFTs", action: "UnEmploy" },
  { title: "UnEmployed NFTs", action: "Employ" },
];

export { D_marketItemList, D_sortList, E_sortList };
