import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { D_faqList, D_issueList, marketPlaceList } from "../data/Dmain";
import Footer from "./Footer";
import Header from "../components/header/Header";
import { useSelector } from "react-redux";
import { getStyle, onClickNextBtn, onClickPreBtn, swiperListener } from "../util/Util";
import { useNavigate } from "react-router-dom";
import SetErrorBar from "../util/SetErrorBar";
import I_upArw3 from "../img/icon/I_upArw3.svg";
import main_Bnr1 from "../img/main/main-visual-temp.png";
import main_Ticket from "../img/main/mainTicket.png";
import main_jackpot_title from "../img/main/jackpot_title.png";
import main_jackpot_val_bg from "../img/main/main_jackpot_val_bg.png";
import main_sub_auc from "../img/main/main_sub_auc.png";
import main_market from "../img/main/main_market.png";
import main_tip from "../img/main/main_tip.png";
import m_main_sub_auc from "../img/main/m_main_sub_auc.png";
import m_main_market from "../img/main/m_main_market.png";
import m_main_tip from "../img/main/m_main_tip.png";
import axios from "axios";
import { API } from "../configs/api";
import { LOGGER, getmyaddress } from "../util/common";
import { setDelinquencyAmount } from "../util/store/commonSlice";
import { net } from "../configs/net";
import { messages } from "../configs/messages";
import PopupBg from "../components/PopupBg";
import PdfPopup from "../components/PdfPopup";

export default function Main() {
  const navigate = useNavigate();
  const headLineRef = useRef();
  const issueRef = useRef();
  const firstAuctionRef = useRef();
  const secondAuctionRef = useRef();
  const marketRef = useRef();
  const ticketRef = useRef();
  let premiumref = useRef();
  const faqRef = useRef();
  let issueIndex = 0;
  const isMobile = useSelector((state) => state.common.isMobile);
  const [headLineIndex, setHeadLineIndex] = useState(0);
  const [firstAuctionIndex, setFirstAuctionIndex] = useState(0);
  const [secondAuctionIndex, setSecondAuctionIndex] = useState(0);
  const [marketIndex, setMarketIndex] = useState(0);
  const [premiumIndex, setPremiumIndex] = useState(0);
  const [ticketIndex, setTicketIndex] = useState(0);
  const [faqIndex, setFaqIndex] = useState(0);
  const [auctionListFirst, setAuctionListFirst] = useState([]);
  const [auctionListSecond, setAuctionListSecond] = useState([]);
  const [likeObj, setLikeObj] = useState({});
  let [premiumitemlist, setpremiumitemlist] = useState([]);
  const [typestrPay, setTypestrPay] = useState([]);
  const [pdfPopup, setPdfPopup] = useState(false);
  const [banners, setBanners] = useState([]);
  let [D_marketItemList, setD_marketItemList] = useState([]);
  let [isstaked, setisstaked] = useState();
  const [ticketInfo, setTickInfo] = useState();
  const [walletStatus, setWalletStatus] = useState("");
  const dispatch = useDispatch();
  const [jackpotVal, setJackpotVal] = useState();

  console.log("auctionListFirst", auctionListFirst);
  function onClickTopBtn() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const onclick_Coming_Soon = () => {{
    SetErrorBar("Coming Soon");
  }};

  const onclick_staked_val_btn = (currentValue) => {
    if (ticketInfo) {
      navigate(currentValue);
    } else {
      SetErrorBar("You need purchased lucky ticket");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // let myaddress = "0xb440393a03078b967000f09577e32c3252f15832";
      let myaddress = getmyaddress();
      if (myaddress) {
      } else {
        SetErrorBar(messages.MSG_PLEASE_CONNECT_WALLET);
        return;
      }
      axios.get(API.API_GET_TICK_INFO + `/${myaddress}?nettype=${net}`).then((resp) => {
        let { status, respdata } = resp.data;
        if (status == "OK" && respdata !== null) {
          setTickInfo(respdata);
          awaitWallet();
        }
      });
    }, 1000);
  }, []);

  const awaitWallet = () => {
    // let myaddress = "0xb440393a03078b967000f09577e32c3252f15832";
    let myaddress = getmyaddress();
    setTimeout(() => {
      if (myaddress) {
        return;
      } else {
        setWalletStatus("Connect Wallet");
      }
    }, 1600);
    setWalletStatus("Connecting...");
  };


  useEffect(() => {
    setTimeout(() => {
      axios.get(API.API_JACKPOT_VAL_GET + `/1/0/120/id/DESC`).then((resp) => {
        let { status, list } = resp.data;
        if (status == "OK") {
          let jackpotVal = list[0].jackpot;
          setJackpotVal(jackpotVal);
        }
      });
    }, 1600);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let address = getmyaddress();
      let myaddress = address;
      console.log("address", address);
      axios
        .get(`${API.API_DELINQUENCY}/${address}/0/10/id/DESC` + `?nettype=${net}&itemdetail=1`)
        .then((res) => {
          console.log("RES", res);
          let { status } = res.data;

          if (status === "OK") {
            let { list } = res.data;
            if (list && list?.length > 0) {
              // const amount = list.reduce((a, b) => a.amount + b.amount, 0);
              let sum = 0;
              list.forEach((item) => {
                sum += +item.amount;
              });
              dispatch(setDelinquencyAmount(sum.toFixed(2)));
              localStorage.setItem("seller", list[0].seller);
              SetErrorBar("Please pay delinquency fee");
              navigate("/penalty");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
      axios
        .get(API.API_RECEIVABLES + `/${address}` + `?nettype=${net}`)
        .then((res) => {
          let { list } = res.data;
          LOGGER("receivables", list);
          if (list?.length > 0) {
            SetErrorBar("exists receivables");
          }
        })
        .catch((err) => console.log(err));
    }, 1500);
  }, []);

  function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];

      array[j] = temp;
    }
    return array;
  }

  function fetchitems() {
    let myaddress = getmyaddress();
    axios
      .get(API.API_COMMONITEMS + `/items/group_/kong/0/128/roundnumber/DESC?nettype=${net}` + `&itemdetail=1`)
      .then((res) => {
        console.log("@query kong: ", res.data);
        let { status, list } = res.data;
        if (status == "OK") {
          let roundNumber1 = list.filter((item) => item.roundnumber > 0);
          roundNumber1 = shuffleArray(roundNumber1);
          setAuctionListFirst(roundNumber1.slice(0, 64));
          setAuctionListSecond(roundNumber1.slice(64));
        }
      });
    if (myaddress) {
      axios.get(API.API_USERINFO + `/${myaddress}?nettype=${net}`).then((resp) => {
        LOGGER("rBojncz0CD", resp.data);
        let { status, respdata } = resp.data;
        if (status == "OK") {
          setisstaked(respdata.isstaked);
        } else {
          false && SetErrorBar("FYI: YOU NEED TO STAKE ");
        }
      });
    }

    axios.get(API.API_TYPESTR + `?nettype=${net}`).then((resp) => {
      LOGGER("itemBalance", resp.data);
      let { status, payload } = resp.data;
      if (status == "OK") {
        setTypestrPay(resp.data.list);
      }
    });
    axios.get(API.API_ALL_ITEMS_MARKET + `/status/1/0/100/id/DESC?nettype=${net}`).then((resp) => {
      LOGGER("itemBalance", resp.data);
      let { status, payload } = resp.data;
      if (status == "OK") {
        setD_marketItemList(resp.data.list);
        setpremiumitemlist(resp.data.list);
      }
    });
  }

  console.log("ddsetD_marketItemList", D_marketItemList);

  useEffect(() => {
    fetchitems();
    setInterval(() => {
      if (!issueRef.current) return;
      if (issueRef.current?.children[0]) {
        const contHeight = issueRef?.current?.children[0]?.offsetHeight;

        issueIndex++;
        if (issueRef.current?.scrollTo) {
          if (issueIndex < D_issueList.length) {
            issueRef.current.scrollTo({
              top: contHeight * issueIndex + issueIndex * getStyle(issueRef, "gap"),
              behavior: "smooth",
            });
          } else {
            issueRef.current.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (banners.length > 0) {
      if (!headLineRef.current) return;
      const wrapWidth = headLineRef.current.offsetWidth;
      const contWidth = headLineRef.current.children[0].offsetWidth;
      const itemNumByPage = Math.floor(wrapWidth / contWidth);

      if (headLineRef.current?.scrollTo) {
        if (headLineIndex === 0) {
          headLineRef.current.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        } else {
          headLineRef.current.scrollTo({
            left: contWidth * itemNumByPage * headLineIndex,
            behavior: "smooth",
          });
        }
      }
    }
  }, [headLineIndex, banners]);

  useEffect(() => {
    swiperListener(firstAuctionRef, firstAuctionIndex);
  }, [firstAuctionIndex]);

  useEffect(() => {
    swiperListener(secondAuctionRef, secondAuctionIndex);
  }, [secondAuctionIndex]);

  useEffect(() => {
    swiperListener(marketRef, marketIndex);
  }, [marketIndex]);

  useEffect(() => {
    swiperListener(premiumref, premiumIndex);
  }, [premiumIndex]);

  useEffect(() => {
    swiperListener(ticketRef, ticketIndex);
  }, [ticketIndex]);

  useEffect(() => {
    swiperListener(faqRef, faqIndex);
  }, [faqIndex]);

  if (isMobile)
    return (
      <>
        <Header />
        <MmainBox>
        <section className="headLineContainer">
        <img className="mainBannerImg" src={main_Bnr1} alt="" />
        </section>

        <section className="backBlack">
          <article className="blackInnerContainer">
            <div className="ticketWrap">
              <img className="mainTickImg" src={main_Ticket} alt=""/>
                <div className="innerTxt">
                  <strong className="title">STAKE LUCKY TICKET</strong>
                  <p className="cont">You can participate<br/>in Subscription Auction<br/>by staking the LUCKY TICKET</p>
                  <a className="itemExLink" href="/#/staking">BUY NOW</a>
                </div>
            </div>
          </article>
          <article
            onClick={() => {
              onclick_staked_val_btn("/mypage");
            }}
            className="wi50"
          >
            <div>
              <img src={main_jackpot_title} />
            </div>
            <div className="valWrap">
              <img src={main_jackpot_val_bg} />
              <p>$ {jackpotVal}</p>
            </div>
          </article>
        </section>
        <img className="autoAuctionBox" src={m_main_sub_auc}
        style={{
          width: "100%"
        }}
        onClick={() => {
          onclick_staked_val_btn("/auction");
        }}
        />

        <section className="itemListContainer">
          <section className="itemListContainer">

          <article className="marketplaceBox itemListBox">
            <strong className="title">Marketplace</strong>
            <img className="autoAuctionBox" src={m_main_market}
              style={{
                width: "100%"
              }}
              onClick={() => {
                onclick_staked_val_btn("/market");
              }}
            ></img>
          </article>

          
          {pdfPopup && (
            <>
              <PdfPopup type="manual" />
              <PopupBg blur off={setPdfPopup} />
            </>
          )}

        </section>
        <article className="tipBox itemListBox"
        style={{"marginTop": "50px"}}
        >
              <img src={m_main_tip}  
              onClick={() => setPdfPopup(true)}
              style={{"marginBottom": "-7px", width:"100%"}}
              />
          </article>

          {pdfPopup && (
            <>
              <PdfPopup type={`manual`} />
              <PopupBg blur off={setPdfPopup} />
            </>
          )}
        </section>

        <button className="topBtn" onClick={() => onClickTopBtn()}>
          <img src={I_upArw3} alt="" />
        </button>
        </MmainBox>
        <Footer />
      </>
    );
  else
    return (
      <>
        <Header />
        <PmainBox>
        <section className="headLineContainer">
          <img className="mainBannerImg" src={main_Bnr1} alt="" >
          </img>
        </section>

        <section className="backBlack">
          <article className="blackInnerContainer">
            <div className="ticketWrap">
              <img className="mainTickImg" src={main_Ticket} alt=""/>
                <div className="innerTxt">
                  <strong className="title">STAKE LUCKY TICKET</strong>
                  <p className="cont">You can participate<br/>in Subscription Auction<br/>by staking the LUCKY TICKET</p>
                  <a className="itemExLink" href="/#/staking">BUY NOW</a>
                </div>
            </div>
          </article>
          <article
            onClick={() => {
              onclick_staked_val_btn("/mypage");
            }}
            className="wi50"
          >
            <div>
              <img src={main_jackpot_title} />
            </div>
            <div className="valWrap">
              <img src={main_jackpot_val_bg} />
              <p>$ {jackpotVal}</p>
            </div>
          </article>
        </section>
        <img className="autoAuctionBox" src={main_sub_auc}
        style={{
          width: "100%"
        }}
        onClick={() => {
          onclick_staked_val_btn("/auction");
        }}
        /> 
        <section className="itemListContainer">

          <article className="marketplaceBox itemListBox">
            <strong className="title">Marketplace</strong>
            <img className="autoAuctionBox" src={main_market}
              style={{
                width: "100%"
              }}
              onClick={() => {
                onclick_staked_val_btn("/market");
              }}
            ></img>
          </article>

          
          {pdfPopup && (
            <>
              <PdfPopup type="manual" />
              <PopupBg blur off={setPdfPopup} />
            </>
          )}

        </section>
        <article className="tipBox itemListBox"
        style={{"marginTop": "50px"}}
        >
              <img src={main_tip}  
              onClick={() => setPdfPopup(true)}
              style={{"marginBottom": "-7px", width:"100%"}}
              />
          </article>
        <button className="topBtn" onClick={() => onClickTopBtn()}>
          <img src={I_upArw3} alt="" />
        </button>
        </PmainBox>
        <Footer />
      </>
    );
}

const MmainBox = styled.div`
  padding: 72px 0 0 0;

  .headLineContainer {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background: rgb(0, 0, 0);
    position: relative;

    .mainBannerImg {
      width: 100%;
  }

    .indexBtn {
      position: absolute;

      &.preBtn {
        left: 3.33vw;

        img {
          height: 3.05vw;
        }
      }
      &.nextBtn {
        right: 3.33vw;

        img {
          height: 3.05vw;
        }
      }
    }
  }

  .backBlack {
    background: rgb(24, 24, 24);
    width: 100%;

    & img {
      width:100%;
    }
  }

  .mainTickImg {
    width: 100%;
    max-width: 300px;
  }

  .wi50 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;

    & div:first-of-type {
      position:relative;
      left:0;
      z-index:1;
    }

    div.valWrap {
      position: relative;
      z-index: 0;

      p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to bottom right, #010101, #3b3b3b);
        border: 5px solid #000;
        border-radius: 50px;
        width: 100%;
        max-width: 250px;
        padding: 1% 2.5%;
        color: #f4c54f;
        text-align: center;
        font-weight: bold;
        font-size: 30px;
      }
    }
  }

  .blackInnerContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
  }

  .ticketWrap {
    display: flex;
    flex-flow: column wrap;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px;
    gap: 50px;
  }

  .innerTxt {
    color: rgb(255, 255, 255);
    text-align: center;
  }

  .title {
    font-size: 2rem;
    line-height: 45px;
  }

  .cont {
    margin-top: 20px;
    color: rgb(204, 204, 204);
    font-size: 20px;
  }

  .itemExLink {
    margin: 40px auto 0px;
    display: block;
    background: rgb(28, 108, 250);
    border-radius: 50px;
    padding: 10px 20px;
    max-width: 150px;
    text-align: center;
  }

  .itemListContainer {
    display: flex;
    flex-direction: column;
    gap: 6.66vw;
    width: 100%;
      & .title {
        margin-bottom: 40px;
        font-size: 2rem;
        display: block;
        text-align: center;
      }
  }

  .topBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12.22vw;
    height: 12.22vw;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #f6f6f6;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    right: 30px;
    bottom: 20px;
    position: fixed;
  }

  
`;

const PmainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 143px 0 0 0;

  .headLineContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #000;
    position: relative;

    img.mainBannerImg {width:100%;}

    ul {
      display: flex;
      width: 100%;
      @media screen and (max-width: 1440px) {
        width: 80%;
      }
      max-width: 1020px;
      height: 420px;
      overflow-x: scroll;

      li {
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        min-width: 100%;
        color: #fff;
        padding: 40px 0;

        /* .leftBox {
          display: flex;
          flex-direction: column;
          margin: 50px 0 40px;

          .interview {
            display: flex;
            align-items: center;
            width: 132px;
            height: 36px;
            padding: 0 16px;
            border: 1.2px solid #ffffff;
            border-radius: 30px;
          }

          .titleBox {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 20px 0 0 0;
            font-weight: 500;

            .title {
              font-size: 44px;
              line-height: 66px;
            }

            .explain {
              font-size: 18px;
              line-height: 27px;
            }
          }

          .bottomText {
            font-size: 18px;
            line-height: 24px;
          }
        } */

        .mainImg {
          margin-left: 10px;
          height: 100%;
        }
      }
    }

    .indexBtn {
      position: absolute;

      &.preBtn {
        left: 60px;
      }
      &.nextBtn {
        right: 60px;
      }
    }
  }

  .wi50 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    & div:first-of-type {
      position:relative;
      left:20px;
      z-index:1;
    }

    div.valWrap {
      position: relative;
      z-index: 0;

      p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to bottom right, #010101, #3b3b3b);
        border: 5px solid #000;
        border-radius: 50px;
        width: 100%;
        max-width: 500px;
        padding: 1% 2.5%;
        color: #f4c54f;
        text-align: center;
        font-weight: bold;
        font-size: 50px;
      }
    }
  }

  .backBlack {
    background: rgb(24, 24, 24);
    width: 100%;

    & img {
      width: 100%;
    }
  }

  .blackInnerContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
  }

  .ticketWrap {
    display: flex;
    justify-content: space-between;
    padding: 50px 20px;
    gap: 50px;
  }

  .mainTickImg {
    width: 100%;
    max-width: 500px;
  }

  .innerTxt {
    color: rgb(255, 255, 255);
  }

  .title {
    font-size: 40px;
    line-height: 45px;
  }

  .cont {
    margin-top: 20px;
    color: rgb(204, 204, 204);
    font-size: 20px;
  }

  .itemExLink {
    margin-top: 40px;
    display: block;
    background: rgb(28, 108, 250);
    border-radius: 50px;
    padding: 10px 20px;
    max-width: 150px;
    text-align: center;
  }

  .itemListContainer {
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 100%;
    max-width: 1480px;

    .itemListBox {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .listBox {
        display: flex;
        flex-direction: column;
      }

      .posBox {
        display: flex;
        align-items: center;
        position: relative;

        .itemList {
          display: flex;
          gap: 40px;
          padding: 20px;
          overflow-x: scroll;
        }

        .nextBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 54px;
          height: 54px;
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid #f6f6f6;
          border-radius: 50%;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          right: -7px;
          @media screen and (max-width: 1440px) {
            right: 20px;
          }
          position: absolute;
          z-index: 2;
        }
      }

      &.autoAuctionBox {
        padding: 16px 0 0 0;

        .detailList {
          border-top: 1px solid #f6f6f6;
        }
      }

      &.ticketBox {
        .item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 330px;
          min-width: 330px;
          height: 440px;
          padding: 20px;
          background: #000;
          box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
          border-radius: 12px;
          overflow: hidden;
          cursor: pointer;

          .topBar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 40px;
            padding: 0 18px;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            color: #fff;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(60px);
            border-radius: 30px;
          }

          .itemImg {
            flex: 1;
            width: 100%;
            object-fit: cover;
          }

          .stakeBtn {
            height: 56px;
            font-size: 18px;
            font-weight: 700;
            font-family: "Roboto", sans-serif;
            color: #fff;
            background: #333;
            border-radius: 30px;
          }
        }
      }

      &.tipBox {
        .tipList {
          display: flex;
          padding: 0 20px;
          height: 308px;
          gap: 40px;
          margin: 20px 0 0 0;

          li {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 52px;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
            border-radius: 12px;
            cursor: pointer;

            img {
              height: 145px;
            }

            p {
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              font-family: "Roboto", sans-serif;
            }
          }
        }
      }

      &.faqBox {
        .posBox {
          .itemList {
            width: 100%;
          }

          .pageBtnBox {
            display: flex;
            gap: 8px;
            top: 0;
            right: 46px;
            position: absolute;
            transform: translate(0, -7px);

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 54px;
              height: 54px;
              border-radius: 50%;
              background: #000;
              position: relative;
              right: unset;
            }
          }
        }
      }
    }
  }

  .marketplaceBox {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto 0px;
  }

  .marketplaceBox .title {
    margin-bottom: 40px;
    font-size: 40px;
    display: block;
    text-align: center;
  }

  .topBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #f6f6f6;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    right: 30px;
    bottom: 20px;
    position: fixed;
  }
`;